@font-face {
  font-family: 'Title-font';
  src: url(../../../assets/fonts/KTFJermilov-Solid.ttf);
}
.main_photo {
  margin: 0 auto;
  width: 100%;
  height: 85vh;
}
.main_title {
  color: white;
  text-align: center;
  position: absolute;
  font-family: 'Title-font';
  font-size: 45px;
  top: 12vw;
  left: 25%;
  right: 25%;
}

@media (max-width: 1500px) {
  .main_photo {
    height: 80%;
  }
  .main_title {
    top: 20vw;
  }
}
@media (max-width: 1250px) {
  .main_title {
    font-size: 35px;
  }
}
@media (max-width: 1000px) {
  .main_title {
    top: 25vw;
    font-size: 30px;
  }
}
@media (max-width: 768px) {
  .main_title {
    font-size: 20px;
    top: 22vw;
  }
}
@media (max-width: 650px) {
  .main_title {
    top: 28vw;
  }
}
@media (max-width: 500px) {
  .main_title {
    font-size: 18px;
    top: 32vw;
  }
}
@media (max-width: 460px) {
  .main_title {
    top: 35vw;
    font-size: 15px;
  }
}
