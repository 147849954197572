.mainCatalog_wrapper {
  /* width: 700px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid black;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0 0 10px 10px #5e5050;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.mainCatalog_wrapper:hover {
  cursor: pointer;
  background-color: #b1a7a5;
  transform: scale(1.01, 1.01);
  -webkit-transform: scale(1.01, 1.01);
  -moz-transform: scale(1.01, 1.01);
  -ms-transform: scale(1.01, 1.01);
  -o-transform: scale(1.01, 1.01);
}
.mainCatalog_title {
  width: 290px;
  margin: 0 5px;
  font-size: 20px;
  text-align: center;
}
.img {
  width: 300px;
  height: 300px;
}
.specifications {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: 18px;
}