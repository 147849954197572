.header__numbers {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 35%;
    color: #000000;
    font-size: 18px;
}
.header__icon {
    width: 30px;
    height: 30px;
}

.header__number {
    color: #000000;
}

.header__numbers__text {
    display: flex;
    gap: 10px;
    vertical-align: baseline;

}

.header__numbers__textlink:hover {
    cursor: pointer;
    /* color: #0b8500; */
}

@media(max-width: 1000px) {
    .header__numbers {
        font-size: 14px;
    }
}

@media(max-width: 768px) {
    .header__numbers {
        width: 75%;
        color: #cee5e3;
    }
    .header__number {
        color: #cee5e3;
    }
}
/* 
@media(max-width: 400px) {
    .header__numbers {
        font-size: 18px;
    }
} */