.information__container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 5%;
  font-size: 18px;
}
.information__contacts_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.information__container {
  display:flex;
  flex-direction: column;
  gap: 10px;
}
.information__btn_show_requisites {
  margin: 0 auto;
  width: 20vw;
  padding: 15px 15px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  background: #858282;
  cursor: pointer;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

@media(max-width: 800px) {
  .information__btn_show_requisites {
    width: 80%;
  }
}