* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

html {
  z-index: -999;
  pointer-events: auto !important;
}

body {
  pointer-events: auto !important;
}

main {
  min-height: 70vh;
}

#root {
  pointer-events: auto !important;
  display: flex;
  flex-direction: column;
  background-color: #b1a7a5;
}

.message {
  width: 50%;
  text-align: center;
  margin: 0 auto;
  margin-top: 5%;
}
