.information__requisites {
  padding: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  background-color: #b1a7a5;
  border: 2px solid #000000;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.information__requisites__title {
  color: #000000;
  font-weight: 700;
  font-size: 22px;
}
.requisit {
  display: flex;
  gap: 2%;
}
.requisit__title {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.requisit__text {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2px;
  border: 3px solid #353232;
  border-radius: 10px;
  box-shadow:0 0 15px 4px rgba(0,0,0,0.06);
  color: #000000;
}
.requisit__subtitle {
  white-space: nowrap
}

@media(max-width: 500px) {
  .information__requisites {
    margin: 0 auto;
    margin-left: -2.5%;
    width: 100%;
  }
  .requisit__title {
    font-size: 14px;
  }
  .requisit__text {
    font-size: 14px;
  }
  .requisit {
    gap: 1%;
  }
}