@font-face {
  font-family: Stolzl;
  src: url(../../../assets/fonts/RezaZulmiSerif.ttf);
}
.about__company_wrapper {
  width: 80vw;
  border: 3px solid #000000;
  background: inherit;
  font-size: 30px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}
.about__company_title {
  padding: 25px 100px;
}
.about__company_title .main__title {
  position: relative;
  color: #000000;
  font-family: Tahoma, sans-serif;
  font-size: 40px;
  font-weight: normal;
  padding: 8px 20px 7px 20px;
  display: inline-block;
  margin: 0;
  line-height: 1;
}
/* .about__company_title .main__title:before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  top: -20px;
  left: -20px;
  border: 4px solid #000000;
  box-sizing: border-box;
} */
.about__company_text {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 2%;
  font-size: 30px;
}
@media (max-width: 1000px) {
  .about__company_wrapper {
    margin: 0 auto;
    width: 70vw;
    order: 1;
  }
}
@media (max-width: 1200px) {
  .about__company_text {
    font-size: 25px;
  }
}
@media (max-width: 930px) {
  .about__company_title .main__title {
    font-size: 30px;
  }
}
@media (max-width: 768px) {
  .about__company_text {
    font-size: 20px;
  }
}
@media (max-width: 600px) {
  .about__company_title {
    padding: 40px 70px;
  }
  .about__company_title .main__title {
    font-size: 25px;
  }
  /* .about__company_title .main__title:before {
    width: 20px;
    height: 20px;
    top: -20px;
    left: -20px;
  } */
}
@media (max-width: 450px) {
  .about__company_wrapper {
    width: 90vw;
  }
  .about__company_title {
    padding: 30px 60px;
  }
  .about__company_title .main__title {
    font-size: 20px;
  }
  /* .about__company_title .main__title:before {
    width: 18px;
    height: 18px;
    top: -18px;
    left: -18px;
  } */
}
@media (max-width: 365px) { 
  .about__company_title .main__title {
    /* width: 160px; */
    font-size: 17px;
  }
}