.header {
  padding: 1em 5%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 5px solid #000000;
  font-family: Georgia, 'Times New Roman', Times, serif;
  /* background: linear-gradient(180deg, #000000 39.76%, #010130 100%); */
  font-size: 1.3em;
  z-index: 500;
}
.navbar__link_main {
  display: none;
}
.navbar__links {
  /* width: 100%; */
  width: 65vw;
  font-size: 23px;
  display: flex;
  justify-content: space-between;
}
.catalog {
  display: none;
}
.navbar__link {
  margin: auto 0;
  text-decoration: none;
  color: #000000;
}
.navbar__link_wrapper {
  margin: auto 0;
}
.navbar__sublink {
  text-decoration: none;
  color: #ffffff;
}
.header__logo {
  width: 6em;
  height: 6em;
}
.header__logo:hover {
  cursor: pointer;
}
.navbar__sublinks_wrapper {
  display: none;
  /* display: flex; */
  gap: 10px;
  position: absolute;
  flex-direction: column;
  padding: 10px 10px 10px 3px;
  border-radius: 5px;
  background-color: rgb(0, 0, 0, 0.5);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.navbar_sublinks_show {
  display: flex;
}

@keyframes show {
  from {right: -100%;}
  to {right: 0%;}
}

@media (max-width: 1000px) {
  .header {
    padding: 0.7em 5%;
  }
  .navbar__links {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .navbar__link {
    color: #cee5e3;
  }
  .navbar__link_wrapper {
    display: none;
  }
  .catalog {
    display: block;
  }
  .header {
    padding: 0.5em 5%;
  }
  .navbar {
    display: none;
    z-index: 100;
    position: absolute;
    background-color: rgba(92, 72, 72, 0.95);
    /* background: linear-gradient(180deg, #000000 39.76%, #010130 100%); */
    right: -100%;
  }
  .navbar.active {
    height: 100vh;
    display: block;
    animation-name: show;
    animation-duration: 0.5s;
    top: 0%;
    right: 0%;
    color: #cee5e3;
  }
  .navbar.active > .navbar__links {
    margin: 0 auto;
    margin-top: 5em;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
  }
  .header__logo {
    width: 5em;
    height: 5em;
  }
}

@media (max-width: 390px) {
  .header {
    padding: 0.5em 3%;
  }
  .header__logo {
    width: 4em;
    height: 4em;
  }
  .navbar__links {
    width: 85vw;
  }
}