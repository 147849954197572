@font-face {
  font-family: Stolzl;
  src: url(../../assets/fonts/RezaZulmiSerif.ttf);
}
.option__advantages {
  list-style-type: circle;
}
.service__company_wrapper {
  padding: 3% 0px;
  width: 85vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.service__company_textwrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.option__title {
  font-family: Tahoma, sans-serif;
  font-size: 30px;
  font-weight: normal;
  font-style: italic;
  padding: 1% 0px;
}
.service__company_textwrapper {
  border: 3px solid #000000;
  background: inherit;
  font-size: 30px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}
.service__company_title {
  width: 100%;
  padding: 10px 85px;
}
.service__company_title .main__title {
  position: relative;
  color: #000000;
  font-family: Tahoma, sans-serif;
  font-size: 35px;
  font-weight: weight;
  padding: 10px 20px 0px 20px;
  display: inline-block;
  
  line-height: 1;
}
.main__title {
  width: 100%;
  text-align: center;
  margin: 0 auto;
}
/* .about__company_title .main__title:before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  top: -20px;
  left: -20px;
  border: 4px solid #000000;
  box-sizing: border-box;
} */
.service__company_text {
  padding-left: 10%;
  padding-right: 10%;
  /* padding-top: 1%; */
  font-size: 30px;
}
@media (max-width: 1000px) {
  .service__company_wrapper {
    margin: 0 auto;
    width: 75vw;
    order: 1;
  }
}
@media (max-width: 1200px) {
  .service__company_text {
    font-size: 25px;
  }
}
@media (max-width: 930px) {
  .service__company_title .main__title {
    font-size: 30px;
  }
  .option__title {
    font-size: 30px;
  }
}
@media (max-width: 768px) {
  .service__company_text {
    font-size: 20px;
  }
}
@media (max-width: 600px) {
  .service__company_title {
    width: 100%;
    padding: 10px 20px;
  }
  .service__company_title .main__title .option__title {
    font-size: 20px;
  }
  .option__title {
    font-size: 20px;
  }
  /* .about__company_title .main__title:before {
    width: 20px;
    height: 20px;
    top: -20px;
    left: -20px;
  } */
}
@media (max-width: 450px) {
  .service__company_wrapper {
    width: 90vw;
  }
  .service__company_title .main__title {
    font-size: 25px;
  }
  .option__title {
    font-size: 25px;
  }
  /* .about__company_title .main__title:before {
    width: 18px;
    height: 18px;
    top: -18px;
    left: -18px;
  } */
}
@media (max-width: 365px) { 
  .service__company_title .main__title {
    /* width: 160px; */
    font-size: 20px;
  }
  .option__title {
    font-size: 20px;
  }
}