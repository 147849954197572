.main_wrapper {
  margin: 3% auto;
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 35px;
}
.mainCatalog_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid black;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0 0 10px 10px #5e5050;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.mainCatalog_wrapper:hover {
  cursor: pointer;
  background-color: #b1a7a5;
  transform: scale(1.05, 1.05);
  -webkit-transform: scale(1.05, 1.05);
  -moz-transform: scale(1.05, 1.05);
  -ms-transform: scale(1.05, 1.05);
  -o-transform: scale(1.05, 1.05);
}
.mainCatalog_title {
  width: 300px;
  margin: 0 5px;
  font-size: 20px;
  text-align: center;
  color: #000000;
}
.img {
  width: 300px;
  height: 300px;
}