.advantages__section_block {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  width: 20vw;
  font-size: 15px;
  background: #ffffff;
  -webkit-box-shadow: 0px 0px 8px 10px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 8px 10px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 8px 10px rgba(34, 60, 80, 0.2);
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}
.advantages__section_block_title {
  margin-bottom: 1%;
  font-size: 20px;
  text-align: center;
}
.advantages__icon {
  width: 5em;
  height: 5em;
}
.advantages__section_block_text {
  border-top: 2px solid black;
  padding-top: 2%;
}

@media (max-width: 1200px) { 
  .advantages__section_block {
    width: 240px;
  }
  .advantages__section_block_title {
    font-size: 18px;
  }
  .advantages__section_block { 
    font-size: 15px;
  }
}